import React from 'react';
import { BsLinkedin, BsInstagram, BsGithub } from 'react-icons/bs';


const SocialMedia = () => (
  <div className="app__social">
    <a href='https://www.linkedin.com/in/vikramabhisheksah/' target='_blank' rel="noreferrer">
      <div>
          <BsLinkedin/>
      </div>
    </a>
    <a href='https://github.com/vikramabhisheksah' target='_blank' rel="noreferrer">
      <div>
        <BsGithub />
      </div>
    </a>
    <a href='https://www.instagram.com/iamvhisky/' target='_blank' rel="noreferrer">
      <div>
        <BsInstagram />
      </div>
    </a>
  </div>
);

export default SocialMedia;